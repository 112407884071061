<template>
	<div class="wrapper">
		<section class="error-section">
			<div class="container">
				<div class="error">
					<h1 class="title">{{ statusCode }}</h1>
					<p class="subtitle">{{ statusTextContent }}</p>
					<div class="buttons" v-if="statusCode !== 401">
						<button class="btn" @click="$router.push('/')">
							Вернуться на главную
						</button>
						<button class="btn" @click="$router.go(-1)">Вернуться назад</button>
						<button class="btn" @click="$router.push('/profile')">
							Вернуться в профиль
						</button>
					</div>
					<div class="buttons buttons_auth" v-if="statusCode === 401">
						<div class="btn-wrap">
							<div class="text">У вас уже есть аккаунт?</div>
							<button class="btn">Войти</button>
						</div>
						<div class="btn-wrap">
							<div class="text">Вы не зарегистрированы?</div>
							<button class="btn">Зарегистрироваться</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	name: 'Error',
	mounted() {
		this.statusCode === 400
			? (this.statusTextContent =
					'Плохой запрос. Мы не смогли понять ваш запрос.')
			: this.statusCode === 401
			? (this.statusTextContent =
					'Вы не авторизованы! Авторизуйтесь, что бы получить информацию страницы.')
			: this.statusCode === 403
			? (this.statusTextContent = 'У вас нет прав доступа. Forbidden...')
			: this.statusCode === 404
			? (this.statusTextContent = 'Страница не найдена.')
			: this.statusCode === 405
			? (this.statusTextContent = 'Метод не поддерживается.')
			: this.statusCode === 500
			? (this.statusTextContent =
					'Ошибка на сервере. Мы уже занимаемся решением ошибки..')
			: this.statusCode === 503
			? (this.statusTextContent =
					'Сервер временно недоступен. Мы уже занимаемся решением ошибки.')
			: this.statusCode === 504
			? (this.statusTextContent =
					'Время ожидания ответа истекло. Сервер не ответил вовремя.')
			: ''
	},
	data() {
		return {
			statusCode: 504,
			statusTextContent: ''
		}
	}
}
</script>

<style lang="sass" scoped>
.container
	width: 1300px
	display: flex
	padding: 0 110px
.error-section
	position: relative
	background: url("../assets/images/error-bg.jpg") center top
	background-size: cover
	padding-top: 170px
	padding-bottom: 400px
	z-index: 0
	&:before
		position: absolute
		content: ''
		top: 0
		width: 100%
		height: 100%
		background: rgba(#ffffff, 0.75)
	.error
		width: 100%
		position: relative
		.title
			font-size: 200px
			line-height: 200px
			font-weight: bold
			margin-bottom: 30px
		.subtitle
			font-size: 36px
			line-height: 43px
			width: 100%
			max-width: 640px
			margin-bottom: 60px
		.buttons
			display: flex
			justify-content: space-between
			max-width: 640px
			.btn
				width: 200px
				height: 40px
			.text
				font-size: 30px
				line-height: 36px
				margin-bottom: 20px
			&_auth
				max-width: 800px

@media screen and (max-width: 1440px)
	.container
		width: 1080px

@media screen and (max-width: 1180px)
	.container
		width: 996px
	.error-section
		padding-top: 140px
		padding-bottom: 325px
		.error
			.title
				font-size: 165px
				line-height: 165px
			.subtitle
				font-size: 32px
				line-height: 39px
			.buttons
				.text
					font-size: 27px

@media screen and (max-width: 1024px)
	.container
		width: 900px
	.error-section
		padding-top: 110px
		padding-bottom: 250px
		.error
			.title
				font-size: 130px
				line-height: 130px
			.subtitle
				font-size: 28px
				line-height: 35px
			.buttons
				.text
					font-size: 25px

@media screen and (max-width: 912px)
	.container
		width: 768px
		padding: 0 60px
	.error-section
		padding-top: 80px
		padding-bottom: 170px
		.error
			.title
				font-size: 100px
				line-height: 100px
			.subtitle
				font-size: 24px
				line-height: 29px
			.buttons
				.text
					font-size: 24px

@media screen and (max-width: 800px)
	.container
		width: 100%
		padding: 0 15px
	.error-section
		background-image: unset
		.error
			text-align: center
			.buttons
				flex-direction: column
				justify-content: center
				align-items: center
				.text
					font-size: 20px
			.btn
				margin-bottom: 20px
</style>
